import React, {useState} from 'react'
import backgroundImage from '../../assets/images/labhe.jpg'
import {Link} from "react-router-dom";

function IncubationContent() {
    const [video, setVideo] = useState();

    return (
        <>
            <section className="event event03 pt-50 pb-120 p-relative fix text-start">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2> <i className="fal fa-graduation-cap" /> Welcome to the Incubation & Acceleration Lab at Cosmopolitan University!</h2>
                            <p>The Incubation & Acceleration Lab is a dynamic and innovative space designed to nurture and support aspiring entrepreneurs, early-stage startups, and innovative projects. Our mission is to provide a conducive environment, resources, and expert guidance to help transform promising ideas into successful and sustainable ventures.</p>
                        </div>
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: `url(${backgroundImage})`}} >
                                <div className="s-video-content text-center">
                                    {/* <h6>
                                        <Link to="#" className="popup-video mb-50" onClick={() => setVideo(true)}>
                                            <img src="/assets/img/bg/play-button.png" alt="circle_right" />
                                        </Link>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                <h4>Here's what you can expect from our Incubation & Acceleration Lab: </h4>
                                <div className="accordion" id="accordionCU">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" >
                                                    Startup Incubation
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We offer comprehensive support to early-stage startups, providing them with the necessary tools and resources to establish and grow their businesses. Our experienced team and network of mentors offer guidance on various aspects of entrepreneurship, including business planning, market analysis, funding strategies, and team building. We create a collaborative ecosystem where entrepreneurs can connect, share insights, and learn from each other's experiences.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                                    Co-Working Space
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                Our Incubation & Acceleration Lab provides a vibrant co-working space where entrepreneurs can work, collaborate, and network with like-minded individuals. The space is equipped with modern facilities, high-speed internet, meeting rooms, and other amenities to foster a productive and creative environment. We encourage collaboration, knowledge sharing, and cross-disciplinary interactions among our community of entrepreneurs.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
                                                    Access to Expertise
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                As part of our incubation program, startups have access to a diverse pool of experts, including industry professionals, experienced entrepreneurs, and domain-specific mentors. These experts provide valuable insights, advice, and guidance tailored to the unique needs of each startup. Our mentorship program aims to accelerate the growth and success of startups by leveraging the knowledge and experience of seasoned professionals.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" >
                                                    Workshops and Training
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                               We organize regular workshops, seminars, and training sessions covering various aspects of entrepreneurship and business development. These sessions are conducted by industry experts and cover topics such as marketing strategies, financial management, legal considerations, product development, and more. Our goal is to equip entrepreneurs with the skills and knowledge they need to navigate the challenges of starting and scaling their ventures.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" >
                                                    Funding Opportunities
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We understand the importance of funding for startups to fuel their growth and innovation. Through our extensive network of investors, venture capitalists, and funding agencies, we connect startups with potential sources of capital. We assist startups in preparing funding pitches, refining their business models, and developing compelling investment proposals. Additionally, we provide information about grants, competitions, and other funding opportunities available to early-stage ventures.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 text-center">
                            <p>Whether you have a groundbreaking idea, a technology-driven startup, or an innovative project, the Incubation & Acceleration Lab at Cosmopolitan University is here to support and empower you on your entrepreneurial journey. Join us to access the resources, expertise, and networks that will fuel your success. Together, let's turn ideas into thriving ventures that make a positive impact on society and the economy.
                                .</p>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style={{overflow: "hidden"}}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)} >×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/ZSiXZxVpVhs?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default IncubationContent
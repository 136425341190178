import React from 'react'
import image1 from '../../assets/images/afr.avif'

function OurStudent() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={image1} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Talk to Our Students
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we value open communication and believe in creating an environment where students' voices are heard and their concerns are addressed. We understand the importance of providing opportunities for students to engage in meaningful dialogue, express their opinions, and receive support. Our "Talk to Our Students" initiative aims to foster an inclusive and supportive community by offering various channels for communication and interaction.
                                    </p>
                                    <p>
                                        <strong>Student Feedback Sessions: </strong>
                                        We actively encourage students to share their feedback, suggestions, and concerns through regular feedback sessions. These sessions provide an opportunity for students to voice their opinions on various aspects of university life, including academic programs, campus facilities, student support services, and extracurricular activities. We value your input and use it to continually enhance the student experience at Cosmopolitan University.
                                    </p>
                                    <p>
                                        <strong>Student Forums and Panels: </strong>
                                        We organize student forums and panels to promote open discussions on topics that matter to our student body. These forums may cover a wide range of subjects, including academic issues, campus policies, career guidance, social concerns, and personal development. By participating in these forums, students have the opportunity to engage with faculty members, staff, and fellow students, fostering a culture of collaborative learning and exchange of ideas.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 col-sm-12 about-content s-about-content pl-15 wow fadeInRight  animated" style={{textAlign: "justify"}}>
                            <div className="col-md-6">
                                <p>
                                    <strong>Peer Mentoring and Support: </strong>
                                    We recognize the importance of peer support in a student's journey. Our peer mentoring program connects incoming students with experienced upperclassmen who can provide guidance, advice, and support. These mentors serve as a valuable resource for new students, helping them navigate university life, manage their academic workload, and make a smooth transition to campus life.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Student Leadership Opportunities: </strong>
                                    Cosmopolitan University is committed to nurturing future leaders. We encourage students to take on leadership roles in student organizations, clubs, and societies. By getting involved in these leadership positions, students gain valuable skills such as teamwork, communication, problem-solving, and decision-making. These opportunities also provide a platform for students to represent their peers, advocate for student interests, and contribute to the university's development.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Student Services and Support: </strong>
                                    Our dedicated student services team is available to address any concerns or queries you may have during your time at Cosmopolitan University. Whether you need guidance on academic matters, assistance with personal challenges, or information about campus resources, our staff is here to support you. You can reach out to us through various channels, including in-person appointments, email, or our online support system.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Dining and Cafeteria: </strong>
                                    Cosmopolitan University understands the importance of nutritious and delicious meals in supporting your overall well-being. Our campus offers a variety of dining options, including a cafeteria that serves a range of meals, snacks, and beverages. Whether you have specific dietary preferences or cultural preferences, you will find options to suit your tastes and preferences.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-10 about-content s-about-content pl-15 wow fadeInRight  animated" style={{textAlign: "justify"}}>
                            <p>Our "Talk to Our Students" initiative aims to create a supportive and collaborative environment that values student feedback, encourages dialogue, and promotes student well-being. Your feedback and input are essential in shaping the university's policies and programs, ensuring that we continually strive for excellence and meet the needs of our diverse student community.
                            </p>
                            <p>We invite you to actively participate in our "Talk to Our Students" initiative and take advantage of the various channels available to share your thoughts, concerns, and ideas. Together, we can work towards making Cosmopolitan University an even better place for all students.
                            </p>
                            <p className="about-content s-about-content pl-15 wow fadeInRight  animated text-center">Remember, your voice matters, and we are here to listen (studentlife@cosmopolitan.edu.ng)!</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStudent;


import React from 'react'
import { Link } from "react-router-dom";
import email from "../../assets/images/contact-icon02.png";

function CentreForAIContent()
{
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Collaborative Centre for AI, Nigeria (CCAI)
                                    </h5>
                                    <hr style={{ border: '1px solid #cccccc', margin: '4px' }} />
                                </div>
                                <div style={{ textAlign: "justify" }}>
                                    <p>Collaborative Center for Artificial Intelligence, based at Cosmopolitan University, is a multidisciplinary space where experts from various fields come together to research, develop, and advance artificial intelligence technologies. This center focuses on fostering collaboration between academia, industry, and government entities to drive innovation in artificial intelligence.</p>
                                    <p>CCAI is a grassroots initiative to bring together those active and interested in AI across Nigeria, and Africa more generally. It is an opportunity to share ideas, funding, resources and shape the direction of AI in Nigeria, Africa and globally. It is an open membership, to join us go to the Membership section.</p>
                                    <h3>CCAI Activities: </h3>
                                    <div className="col-lg-12">
                                        <div
                                            className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                                            data-animation="fadeInUp"
                                            data-delay=".4s"
                                        >
                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseThree"
                                                            >
                                                                Providing a base for collaborative activity covering AI based:
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseThree"
                                                        className="collapse show"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-book"> Research</span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-handshake"> Consultancy</span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-pen-nib"> Training </span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-people-carry"> Collaboration </span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseOne"
                                                            >
                                                                Existing Collaborations include:
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseOne"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-globe"> International</span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><span className="fal fa-map"> National</span></Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                                    <div className="services-box07">
                                                                        <div className="sr-contner">
                                                                            <div className="text">
                                                                                <h4><Link to="#"><i className="fal fa-bars" /> Multidisciplinary collaborations </Link></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>CCAI collaborates with National and international universities, research institutions, and laboratories to undertake joint research projects. This fosters diversity in perspectives, access to varied resources, and a wider talent pool. This center also collaborates with industries, startups, and tech companies to apply AI solutions in real-world scenarios. These collaborations often lead to the development of innovative products or services. CCAI Works with government agencies and departments to address policy concerns, regulatory frameworks, and ethical guidelines related to AI thus influencing national and international policies on AI.</p>
                                    <p>CCAI Joint Funding Initiatives allow for the pooling of resources and securing funding from multiple national and international sources for collaborative AI projects. This increases the financial support available for research endeavors. CCAI Creates platforms or networks that enable the sharing of datasets, tools, and resources among collaborating institutions to foster a more efficient and productive research environment.</p>
                                    <h3>CCAI Internship</h3>
                                    <p>Embark on a transformative journey at CCAI where innovation meets opportunity. Our Internship Program in Artificial Intelligence is your gateway to a dynamic world of cutting-edge research, hands-on learning, and real-world impact. Join a collaborative community of trailblazers, where curiosity sparks innovation and diversity fuels creativity. As an intern, you'll be part of a vibrant ecosystem, working alongside top researchers, engineers, and visionaries shaping the future of AI. Our program isn’t just about observing – it's about active participation. Dive into immersive projects spanning machine learning, computer vision, natural language processing, robotics, and more. Gain invaluable experience through hands-on experiments, access to state-of-the-art technologies, and mentorship from industry experts.</p>

                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <h3>Opportunities for new Interns</h3>
                                            <p>Here at CCAI the potential opportunities for interns within a Center are countless but here are some</p>
                                            <div className="col-lg-12">
                                                <div className="faq-wrap mt-30 pr-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="card">
                                                            <div className="card-header" id="headingFour">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                                                        Hands-on Research Experience
                                                                    </button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseFour" className="collapse show" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    <p>Interns can actively participate in ongoing research projects, assisting researchers and contributing to experiments in various AI domains such as machine learning, computer vision, natural language processing, robotics, etc.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingFive">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive">Skill Development</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseFive" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Opportunities to enhance technical skills by working with cutting-edge tools, software, and methodologies relevant to AI research and development.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingSix">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix">Mentorship</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseSix" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Access to mentorship from experienced professionals and researchers, providing guidance, support, and insights into the field of AI.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingSeven">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven">Collaboration</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseSeven" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Engaging in collaborative work with multidisciplinary teams, learning how to effectively work in a team environment and leverage diverse perspectives.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingEight">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight">Networking</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseEight" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Participation in workshops, seminars, and networking events, providing exposure to industry experts, academics, and professionals within the AI ecosystem.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingNine">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine">Problem Solving</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseNine" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Exposure to real-world challenges, allowing interns to apply theoretical knowledge to solve practical problems and contribute meaningfully to projects.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingTen">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen">Innovation Opportunities</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseTen" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Encouragement to explore innovative ideas, contribute novel concepts, and potentially develop prototypes or proof-of-concepts in AI-related fields.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingEleven">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven">Project Ownership</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseEleven" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Depending on the internship duration and project scope, interns might have the chance to take ownership of certain aspects of a project, fostering independence and initiative.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingTwelve">
                                                                <h2 className="mb-0">
                                                                    <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve">Publication and Recognition</button>
                                                                </h2>
                                                            </div>
                                                            <div id="collapseTwelve" className="collapse" data-bs-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    Opportunities to contribute to academic papers, publications, or presentations, providing valuable recognition and exposure within the AI community.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <h3>Specialisms</h3>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <h4>AI Testing</h4>
                                                    <p>-Two-Fold, and Multi-Fold models of Generative AI testing and validating</p>
                                                    <p>-Affordance modeling</p>
                                                    <p>-Capability Modeling – mapping capabilities and impacts of AI</p>
                                                    <p>-Bounds of Applicability, and Bounds of Non-Applicability</p>
                                                    <p>-Metadata models of AI testing and auditing</p>
                                                    <p>-Tools and techniques for AI development</p>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <h4>Generative AI</h4>
                                                    <p>-AI Co-creation tools</p>
                                                    <p>-Application support and training</p>
                                                    <p>-Generative AI training and validating</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">


                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="inner-column3">

                                            </div>
                                        </div>
                                    </div>

                                    <h3>Current Projects and Interests</h3>
                                    <div className="col-lg-12">
                                        <div className="faq-wrap mt-30 pr-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                            <div className="accordion" id="accordionExample">
                                                <div className="card"><div className="card-header"><h2 className="mb-0"><button className="faq-btn" type="button">AI in Music</button></h2></div></div>
                                                <div className="card"><div className="card-header"><h2 className="mb-0"><button className="faq-btn" type="button">AI Testing and Validating</button></h2></div></div>
                                                <div className="card"><div className="card-header"><h2 className="mb-0"><button className="faq-btn" type="button">AI autonomics</button></h2></div></div>
                                                <div className="card"><div className="card-header"><h2 className="mb-0"><button className="faq-btn" type="button">AI Metadata and Standards (AI standards Hub; AI-LOM standard)</button></h2></div></div>
                                                <div className="card"><div className="card-header"><h2 className="mb-0"><button className="faq-btn" type="button">Ethics and AI</button></h2></div></div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3>Membership</h3>
                                    <p>This is a grassroots collaboration, and we welcome members from across the spectrum of interests in AI. It is open for wider membership for business and academic collaborators, including National CCAI Associate members and International CCAI Associate Members</p>
                                    <strong>Note: We have open vacancies for 3 further CCAI board members across Nigeria to help shape the direction of the CCAI. If you are interested in being a Board Member of CCAI please indicate in your contact email or via the <a style={{ color: "blue", textDecoration: "underline" }} href='https://docs.google.com/forms/d/e/1FAIpQLScLO1P94CXZDhJL6cpC9WibJmCzSZ-rtyMNAXvg2cRx0irjzw/viewform?usp=sharing' target='_blank'>CCAI interest form</a></strong>

                                    <p>Interested in joining the CCA? contact us to discuss your interests</p>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="services-box text-center active">
                                            <div className="services-icon">
                                                <img src={email} alt="image" />
                                            </div>
                                            <div className="services-content2">
                                                <h5>
                                                    <a href={`mailto:ccai@cosmopolitan.edu.ng`}>{`ccai@cosmopolitan.edu.ng`}</a>
                                                </h5>
                                                <p>Contact Email Address</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default CentreForAIContent
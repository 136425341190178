import React from 'react'

export default function CWIthai()
{
    return (
        <div style={{ height: '100%', position: 'relative' }}>
            <embed src='https://docs.google.com/document/d/e/2PACX-1vR4NGY8J1JEjbJNofwwlD5slpX_GcwDmyvxatCrMB_WM_jSpAkUotqttROieoAEIZEaSzmwWdjd0ejw/pub'
                style={{ width: '100%', height: '700px', overflowY: 'auto' }} />

            <br />
            <br />
        </div>
    )
}

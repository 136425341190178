import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import backgroundImage from '../../assets/images/space.jpg'
import MakerSpaceContent from "./maker-space-content";

function MakerSpace() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Maker Space" bgImage={backgroundImage}/>
            <MakerSpaceContent/>
        </>
    )
}

export default MakerSpace
import React from 'react'
import { Link } from 'react-router-dom'
import BredCom from '../Bredcom/bredcom'


export default function Library() {
    return (
        <>
            <BredCom title="Home" subtitle="Library" bgImage={require("./COSMOPOLITAN UNIVERSITY 062.jpg")} />
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="pricing-box pricing-box2 active mb-60">
                                <div>
                                    <div className="pricing-head text-left">
                                        <h3>Library Guides</h3>
                                    </div>
                                    <div className="pricing-body mb-10 text-left">
                                        <p>Find books, media, databases, e-journals and digital resources.</p>
                                        <Link to="https://koha.cosmopolitan.edu.ng/" target='_blank'  className="link-underline-opacity-0-hover">
                                            Read More&emsp;<i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className="pricing-head text-left">
                                        <h3>Borrow, Reserve, Renew</h3>
                                    </div>
                                    <div className="pricing-body mb-10 text-left">
                                        <p>Get advanced research assistance in dozens of subject areas.</p>
                                        <Link to="https://koha.cosmopolitan.edu.ng/" target='_blank'  className="link-underline-opacity-0-hover">
                                            Read More&emsp;<i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className="pricing-head text-left">
                                        <h3>Study Spaces & PCs</h3>
                                    </div>
                                    <div className="pricing-body mb-10 text-left">
                                        <p>Deposit data, papers, and other resources for long-term access.</p>
                                        <Link to="https://koha.cosmopolitan.edu.ng/" target='_blank'  className="link-underline-opacity-0-hover">
                                            Read More&emsp;<i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <section className="" style={{ backgroundImage: `url(${require("../../assets/images/banner3_dark.png")})` }} >
                                <div className="container">
                                    <div className="row justify-content-center p-5 m-5" style={{ backgroundImage: `url(${require("../../assets/images/banner3_dark.png")})` }}>
                                        <div className="col-lg-12">
                                            <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                                                <h2>Recollect your past <br/>
                                                <span className='active'>For the future</span></h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 text-right">
                                            <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                                                <Link to="https://koha.cosmopolitan.edu.ng/" target='_blank' className="btn ss-btn smoth-scroll">
                                                    Explore Our Library <i className="fal fa-long-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import ResearchContent from "./research-content";
import backgroundImage from '../../assets/images/research.jpg'

function ResearchArea() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Research Area" bgImage={backgroundImage}/>
            <ResearchContent/>
        </>
    )
}

export default ResearchArea
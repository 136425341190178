import React, {useState} from "react";
import { Link } from "react-router-dom";
import Bredcom from "../Bredcom/bredcom";
import BgImage from "../../assets/images/banner1_home.png"

function ImpactStory() {
    const [video, setVideo] = useState();
    return (
        <>
            <Bredcom
                title="Innovation"
                subtitle="Impact Story"
            />

            <section className="event event03 pt-50 pb-120 p-relative fix text-start">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2>Welcome to the Impact Story page of Cosmopolitan University</h2>
                            <p>At Cosmopolitan University, we are committed to making a positive and lasting impact on society, industries, and communities through our academic programs, research initiatives, and community engagement efforts. Here, we share inspiring stories that highlight the transformative work and contributions of our students, faculty, and alumni</p>
                            <p>Our Impact Stories showcase the diverse range of ways in which Cosmopolitan University is making a difference. From groundbreaking research breakthroughs to innovative solutions for societal challenges, we celebrate the achievements and endeavors that create meaningful change. These stories demonstrate our dedication to excellence, collaboration, and driving positive outcomes.</p>
                        </div>
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: `url(${BgImage})` }} >
                                <div className="s-video-content text-center">
                                    {/* <h6>
                                        <Link to="#" className="popup-video mb-50" onClick={() => setVideo(true)}>
                                            <img src="/assets/img/bg/play-button.png" alt="circle_right" />
                                        </Link>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                <h4>Explore our Impact Stories to learn about</h4>
                                <div className="accordion" id="accordionCU">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" >
                                                    01 Research Innovations
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse show" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                Discover how our researchers are pushing the boundaries of knowledge across various disciplines. From advancements in medical sciences to cutting-edge technology solutions, our faculty and students are at the forefront of transformative research. These stories highlight the impact of their work, from improving healthcare outcomes to solving pressing environmental issues.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                                    02 Community Engagement
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                Engaging with the community is an integral part of our university's ethos. Our Impact Stories showcase initiatives that address societal needs and create meaningful change. Whether it's through volunteering projects, community partnerships, or social entrepreneurship, we demonstrate our commitment to making a positive difference in the lives of individuals and communities.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
                                                    03 Industry Collaborations
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We believe in fostering strong ties with industry partners to drive innovation and economic growth. Our Impact Stories feature successful collaborations between Cosmopolitan University and industry leaders. These stories highlight the outcomes of collaborative research projects, entrepreneurship initiatives, and the impact of our graduates in various industries.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" >
                                                    04 Entrepreneurial Success
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We take pride in nurturing entrepreneurial talent and empowering our students and alumni to become changemakers and innovators. Our Impact Stories feature inspiring stories of startups and ventures that originated from our university. Learn about the entrepreneurial journeys, successes, and societal impact created by our enterprising students and alumni.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" >
                                                    05 Social Impact Initiatives
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                At Cosmopolitan University, we believe in leveraging education and knowledge to create positive social change. Our Impact Stories highlight initiatives and projects that address social challenges, promote equality and inclusion, and make a difference in the lives of marginalized communities. These stories exemplify our commitment to being a force for positive social impact.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <p>By sharing these Impact Stories, we aim to inspire, inform, and ignite a passion for creating change. We believe that every individual has the potential to make a difference, and we celebrate those who are driving positive impact through their work, research, and initiatives.</p>
                            <p>Join us on this journey of discovery and transformation as we continue to make a tangible and meaningful impact on the world around us. Explore our Impact Stories and be inspired by the stories of individuals and projects that are shaping a better future.</p>
                            <p>Together, let's create an enduring legacy of impact and innovation at Cosmopolitan University.</p>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style={{overflow: "hidden"}}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)} >×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/ZSiXZxVpVhs?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}



export default ImpactStory;

import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import backgroundImage from '../../assets/images/tech.jpg'
import TechnologyLicensingOfficeContent from "./technology-licensing-office-content";

function TechnologyLicensingOffice() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Technology Licensing Office" bgImage={backgroundImage}/>
            <TechnologyLicensingOfficeContent/>
        </>
    )
}

export default TechnologyLicensingOffice
import React from 'react'
import  "../style.css"
export default function LeadershipModal(props) {
    const closeModal = () => {
        const modalContainer = document.getElementById('modalContainer');
        modalContainer.style.display = 'none';
    }

    return (
        <div id="modalContainer" style={{width: '100%'}}>
            <div id="modalContent" className="col-md-6 col-12">
                <span className="close" onClick={(()=> closeModal())}>&times;</span>
                <h4>{props.Name}</h4>
                <div id="imageContent">
                    <img src={props.Image}
                         alt={props.Name}
                         className="img-thumbnail"
                         style={{
                             height: 350,
                             width: 400,
                             marginTop: '20px',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                         }} /><br/>
                </div>

                <p style={{marginTop: '20px'}}>{props.Description}</p>
            </div>
        </div>
    );
}
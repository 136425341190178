import React from 'react'
import { Link } from 'react-router-dom'

function HomeAbout() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix" style={{ background: "#eff7ff" }} >
                <div className="animations-02">
                    <img src={require("../../assets/images/bg/an-img-02.png")} alt="contact-bg-an-01" />
                </div>

                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12" >
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s"  >
                                {/*<img src={require("../../assets/images/COSMOPOLITAN UNIVERSITY 097-3.jpg")} alt="img" style={{ borderRadius: "10px" }} />*/}
                                <img src={require("../../assets/images/full_photo.jpg")} alt="img" style={{ borderRadius: "10px" }} />
                                {/* <div className="about-text second-about">
                                    <span>
                                        5 <sub>+</sub>
                                    </span>
                                    <p>Years of Experience</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> About Our University
                                    </h5>
                                    <h2>Cosmopolitan in Brief</h2>
                                </div>
                                {/*<p className="txt-clr" style={{ textAlign: "justify" }}>*/}
                                <p style={{ textAlign: "justify" }}>
                                    Cosmopolitan University is a pioneering institution approved by the Federal Executive Council on May 16th, 2023. Our university provides a student-centered learning experience that is both innovative and of international standard. With a focus on research, education, and active engagement with industry and society, we aim to expand knowledge and prepare graduates to make a positive impact in their careers, environment, and communities.
                                </p>
                                <p style={{ textAlign: "justify" }}>
                                    At Cosmopolitan University, we embrace technology-driven learning, recognizing the transformative power it holds in today's world. Our commitment to research-driven innovations allows us to stay at the forefront of cutting-edge discoveries and developments. While we embrace the possibilities offered by artificial intelligence, we also ensure appropriate regulations are in place to ensure ethical and responsible use. One of the distinguishing features of Cosmopolitan University is our international faculty, comprised of experienced educators from around the world. This diverse and accomplished team brings a wealth of knowledge and global perspectives to our classrooms, enriching the learning experience for our students

                                </p>
                              
                                <div className="slider-btn mt-20">
                                    <Link to="/about-us" className="btn ss-btn smoth-scroll">
                                        Read More <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout

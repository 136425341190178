import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import IndustryEngagementContent from "./industry-engagement-content";
import Touch from "../contact-us/Touch";

function IndustryEngagement() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Industry Engagement"/>
            <IndustryEngagementContent/>
            <Touch/>
        </>
    )
}

export default IndustryEngagement
import React from 'react'

export default function HomePride() {
    return (
        <section className="steps-area p-relative" style={{ backgroundColor: "#032e3f" }} >
            <div className="animations-10">
                <img src="/assets/img/bg/an-img-02.png" alt="an-img-01" />
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="section-title mb-35 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                            <h2>Why Us</h2>
                            <p>
                                At Cosmopolitan University, we are committed to delivering academic programs of the highest quality. Our distinguished faculty members are experts in their respective fields and are passionate about imparting knowledge and fostering critical thinking skills in our students.
                            </p>
                        </div>
                        <ul className="pr-20">
                            <li>
                                <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="dnumber">
                                        <div className="date-box">
                                            <img src="/assets/img/icon/fea-icon01.png" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h3>Innovative Learning</h3>
                                        <p>
                                            Our university embrace a technology-driven learning, ensuring that our students have access to cutting-edge tools and resources that enhance their educational experience.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="dnumber">
                                        <div className="date-box">
                                            <img src="/assets/img/icon/fea-icon02.png" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h3>Affordable Courses</h3>
                                        <p>
                                            Our programs are affordable and provides value for amount paid. We provide quality education at half price of overseas universities
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="dnumber">
                                        <div className="date-box">
                                            <img src="/assets/img/icon/fea-icon03.png" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="text">
                                        <h3>Global Perspective</h3>
                                        <p>
                                            At Cosmopolitan University, we strive to provide an international experience for our students. We have a diverse and inclusive community, with faculty members from around the world, offering a rich multicultural learning environment.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="step-img wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                            <img src={require("../../assets/images/banner1_home.png")} alt="class image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

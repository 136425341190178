import React  from 'react'
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
import {Link} from "react-router-dom";

function CommunityEngagementContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Community Engagement at Cosmopolitan University
                                    </h5>
                                    <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we believe in the power of community engagement and the positive impact it can have on society. We are committed to actively engaging with our local and global communities to address societal challenges, foster sustainable development, and promote social responsibility. Through meaningful partnerships and collaborative initiatives, we strive to make a difference in the lives of individuals and communities. Here's an overview of our community engagement efforts:
                                    </p>
                                    <p>
                                        <strong>1. Collaborative Programs: </strong>
                                         We develop and implement collaborative programs that directly address the needs of our local communities. These programs are designed to empower individuals, enhance education, promote health and well-being, and contribute to the overall development of communities. By working closely with community organizations, non-profit groups, and local stakeholders, we ensure that our initiatives are relevant, impactful, and sustainable.
                                    </p>
                                    <p>
                                        <strong>2. Volunteerism and Service-Learning: </strong>
                                        We encourage our students, faculty, and staff to actively engage in volunteerism and service-learning activities. Through these experiences, our university community develops a deep understanding of societal issues, empathy, and a commitment to social change. By leveraging their knowledge and skills, our community members contribute to community development projects, participate in outreach programs, and provide valuable support to community organizations.
                                    </p>
                                    <p>
                                        <strong>3. Research for Community Impact: </strong>
                                        We conduct research that addresses pressing community issues and contributes to evidence-based solutions. Our faculty and researchers collaborate with community stakeholders to identify research priorities, conduct studies, and develop innovative approaches to tackle societal challenges. By leveraging our research expertise, we aim to drive positive change and improve the quality of life for individuals and communities.
                                    </p>
                                    <p>
                                        <strong>4. Partnerships with Community Organizations: </strong>
                                        We actively collaborate with community organizations, non-governmental organizations (NGOs), and local institutions to maximize our impact. These partnerships allow us to pool resources, expertise, and networks to create sustainable solutions and amplify the reach of our community engagement initiatives. By working together, we can achieve greater collective impact and address complex societal issues more effectively.
                                    </p>

                                    <p>
                                        <strong>5. Capacity Building and Skills Development: </strong>
                                        Capacity Building and Skills Development: We believe in empowering individuals and communities through capacity building and skills development initiatives. We offer training programs, workshops, and mentorship opportunities that enhance the capabilities of community members, foster entrepreneurship, and support economic empowerment. By equipping individuals with the necessary skills, we contribute to their long-term success and enable them to contribute meaningfully to society.

                                    </p>
                                    <p>
                                        <strong>6. Social Entrepreneurship and Innovation: </strong>
                                        We support and promote social entrepreneurship and innovation as powerful tools for positive change. We encourage our students and community members to develop innovative solutions to social and environmental challenges, supporting them through mentorship, incubation programs, and access to resources. By nurturing a culture of social innovation, we foster entrepreneurial mindsets and contribute to sustainable development.
                                    </p>
                                    <p>
                                        <strong>7. Public Engagement and Knowledge Dissemination: </strong>
                                         We actively participate in public engagement activities, knowledge sharing events, and community forums. We organize seminars, workshops, and public lectures that bring together experts, policymakers, community members, and academics to discuss critical issues and explore potential solutions. By facilitating dialogue and knowledge dissemination, we contribute to informed decision-making and empower communities with the necessary information to drive change.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    At Cosmopolitan University, community engagement is a core value embedded in our educational mission. We are dedicated to creating meaningful connections, fostering social responsibility, and making a lasting impact on the communities we serve. Together, we can build a better future through collaboration, compassion, and collective action.
                                </p>
                                <p>
                                    Join us in our community engagement efforts and become part of a vibrant network of change makers committed to building a more inclusive, sustainable, and prosperous society. Together, we can make a difference.
                                </p>
                            </div>
                            <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CommunityEngagementContent
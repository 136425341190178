import React from 'react'
import image1 from '../../assets/images/banner1.png'

function Campus() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={image1} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Life on University Campus
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        Cosmopolitan University offers a vibrant and enriching campus life, where students have the opportunity to engage in a wide range of activities, explore their interests, and build lifelong friendships. We believe that learning extends beyond the classroom, and our campus is designed to foster personal growth, cultural exchange, and holistic development. Here is a glimpse of what life on campus entails:
                                    </p>
                                    <p>
                                        <strong>Student Clubs and Organizations: </strong>
                                        Cosmopolitan University boasts a diverse range of student clubs and organizations catering to various interests, hobbies, and academic disciplines. Whether you are passionate about sports, arts, entrepreneurship, community service, or cultural activities, you will find a community of like-minded individuals to connect with. These clubs provide a platform for personal growth, leadership development, and the opportunity to organize events and initiatives that contribute to the campus and local community.
                                    </p>
                                    <p>
                                        <strong>Cultural and Social Events: </strong>
                                        Throughout the academic year, Cosmopolitan University hosts a variety of cultural and social events that celebrate our rich diversity and promote inclusivity. From cultural festivals and music performances to talent shows and guest lectures, there is always something happening on campus to engage and entertain you. These events provide a chance to learn about different cultures, showcase talents, and create lasting memories.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 col-sm-12 about-content s-about-content pl-15 wow fadeInRight  animated" style={{textAlign: "justify"}}>
                            <div className="col-md-6">
                                <p>
                                    <strong>Sports and Fitness Facilities: </strong>
                                    We believe in the importance of maintaining a healthy lifestyle and promoting physical well-being. Our students have the opportunity, and are encouraged, to participate in a variety of sports including football, volleyball, table tennis, badminton, yoga among others.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Student Support Services: </strong>
                                    At Cosmopolitan University, we prioritize the well-being and success of our students. We have dedicated support services in place to assist you throughout your academic journey. Our counseling services provide guidance and support for personal, academic, and career-related challenges. Additionally, our academic advisors are available to help you navigate your course selections, monitor your progress, and explore internship and career opportunities.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Campus Facilities: </strong>
                                    Our campus is equipped with modern facilities to enhance your learning experience. These include well-equipped classrooms, laboratories, libraries, computer labs, and research centers. We provide access to digital resources and online platforms to support your academic pursuits. Additionally, our campus is designed to be accessible and inclusive, ensuring that all students have equal opportunities to thrive.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Dining and Cafeteria: </strong>
                                    Cosmopolitan University understands the importance of nutritious and delicious meals in supporting your overall well-being. Our campus offers a variety of dining options, including a cafeteria that serves a range of meals, snacks, and beverages. Whether you have specific dietary preferences or cultural preferences, you will find options to suit your tastes and preferences.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12" style={{textAlign: "justify"}}>
                            <p>At Cosmopolitan University, we strive to create an inclusive, supportive, and engaging campus environment that enables our students to thrive academically, personally, and socially. We believe that your university experience should be transformative and memorable, and we are committed to providing the resources, opportunities, and support you need to make the most of your time on campus</p>
                            <p>We invite you to join our vibrant campus community at Cosmopolitan University and embark on a fulfilling journey of growth, learning, and self-discovery.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Campus;


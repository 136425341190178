import React  from 'react'
import { Link } from 'react-router-dom';
import Img from '../../../assets/images/katampe.png'
import Bredcom from "../../Bredcom/bredcom";
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../../resources/constants";

function PCWelcomeMessage() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Pro Chancellor Message"/>

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__content">
                                    <div className="bsingle__post-thumb mb-20">
                                        <div className="row">
                                            <div className="col-md-10 offset-sm-1">
                                                <img className="img-thumbnail" src={Img} alt="" />
                                                <div className="text-center mt-0">
                                                    <h2 style={{marginBottom: '-5px'}}>Prof. Ibrahim Katampe</h2>
                                                    <small style={{marginTop: '-50px'}}> (Pro-Chancellor) </small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{textAlign: "justify"}}>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Actualisation of Cosmopolitan University marks the realisation of a lifelong desire by the promoters to establish and nurture a student-centred institution in Nigeria.
                                        </p>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Our Institution would utilise technologies to offer innovative and experiential learning programs with academic excellence as part of its DNA at a fraction of the cost of studying overseas. To achieve our mission and vision, the university assembled experienced Managers, Faculty and Administrators from within and outside Nigeria to deliver future-ready education and cutting-edge research to support our Society and Industry.
                                        </p>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            To ensure accountability, the university has appointed a Board that is comprised of individuals who have integrity, experience cutting across public and private sectors and united by their love of education. I am delighted to welcome you to Cosmopolitan University as a student, staff, parent, guardian or stakeholder. Together, we aim for the summit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/about/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PCWelcomeMessage

import React, { useEffect, useState }  from 'react'
import Bredcom from "../../../component/Bredcom/Main";
import {Link} from "react-router-dom";
import axios from "axios";
import { serverLink, shortCode } from "../../../resources/constants";
import SpinnerLoader from "../../../component/common/spinner-loader";
function GalleryView(props) {
    const [galleryCat, setGalleryCat] = useState([]);
    const [subCat, setSubCat] = useState([]);
    const [galleryImageMui, setGalleryImageMui] = useState([]);
    const [current, setcurrent] = useState([]);
    const [loading, setLoading] = useState(false);

    async function getGalleryImages() {
        await axios
            .get(`${serverLink}gallery/list`)
            .then((response) => {
                const data = response.data;
                let gallery_images = [];
                let Category = [];
                let Subcategory = [];
                data.map((gallery, index) => {
                    gallery_images.push({
                        categoryName: gallery.CategoryName, // require
                        subCategoryName: gallery.SubCategoryName, // require
                        title: gallery.ImageTitle, // require
                        original:  `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath
                            :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // require
                        thumbnail: `${gallery.ImagePath !== null ? gallery.ImagePath.includes("simplefileupload") ? gallery.ImagePath
                            :  `${serverLink}public/uploads/${shortCode}/gallery/${gallery.ImagePath}` : ""}`, // optional
                    });

                    Category.push(gallery.CategoryName);
                    Subcategory.push({
                        subCatName: gallery.SubCategoryName,
                        catName: gallery.CategoryName,
                    });
                });
                setGalleryImageMui(gallery_images);
                setcurrent(gallery_images)

                let uniqueCategory = Category.filter(
                    (item, i, ar) => ar.indexOf(item) === i
                );

                setGalleryCat(uniqueCategory);
                setSubCat(Subcategory);
                setLoading(false)
            })
            .catch((error) => {
                console.log("SERVER ERROR", error);
            });
    }

    useEffect(() => {
        getGalleryImages();
        const element = document.getElementById("View_All");
        element.classList.add('active');
    }, []);


    function previewImage(imageUrl) {
        let previewImage = document.getElementById("preview-image");
        previewImage.src = imageUrl;
        let previewContainer = document.getElementById("preview-container");
        previewContainer.style.display = "block";
    }

    function closePreview() {
        let previewContainer = document.getElementById("preview-container");
        previewContainer.style.display = "none";
    }

    const Filter = (category) =>{
        const elements = document.getElementsByClassName('active');
        for (let i = 0; i < elements.length; i++) {
            const classNames = elements[i].className.split(' ');
            if (classNames.includes('active')) {
                elements[i].classList.remove('active');
            }
        }


        const arr = [];
        if(category === "View All"){
            const element = document.getElementById("View_All");
            element.classList.add('active');
            setcurrent(galleryImageMui)
        }
        else{
            galleryImageMui.map((item)=> {
                if(item.categoryName === category){
                    arr.push(item);
                    const replacedString = category.toString().replace(/ /g, '_');
                    const element = document.getElementById(replacedString);
                    element.classList.add('active');
                }
            })
            setcurrent(arr);
        }
    };



    return (loading ? <SpinnerLoader/> :
            <>
                <Bredcom title="Home" subtitle="Gallery"/>
                <section className="team-area2 fix p-relative pt-120 pb-80">
                    <div className="container">
                        <div className="portfolio ">
                            <div className="row align-items-end">
                                <div className="col-lg-12">
                                    <div className="my-masonry text-center mb-50 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                        <div className="button-group filter-button-group ">
                                            <button  id={"View_All"} data-filter="*" onClick={()=> Filter("View All")} > View All </button>
                                            {
                                                galleryCat.length > 0 && galleryCat.map((item, index) => {
                                                    const replacedString = item.replace(/ /g, '_');
                                                    return (
                                                        <button key={index} id={replacedString} data-filter={`.${item}`} onClick={()=> Filter(item)} >{item}</button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid col3 row wow fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                {
                                    current.map((item, index) =>
                                        <div className="grid-item financial col-4" key={index}>
                                            <Link onClick={()=>previewImage(item.thumbnail)}>
                                                <figure className="gallery-image" >
                                                    <img className="img-thumb" src={item.thumbnail} alt="img" />
                                                </figure>
                                            </Link>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </section>
                <div id="preview-container">
                    <span className="close-btn" onClick={()=>closePreview()}>&times;</span>
                    <img id="preview-image" src="" alt="Preview Image"/>
                </div>
            </>
    )
}

export default GalleryView;
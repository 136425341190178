import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import CentreForAIContent from "./centre-for-ai-content";
import centreForAIImg from '../../assets/images/centre-for-ai.png'

function CentreForAI() {
    return (
        <>
            <Bredcom title="Home" bgImage={centreForAIImg} subtitle="Collaborative Centre for AI"/>
            <CentreForAIContent/>
        </>
    )
}

export default CentreForAI
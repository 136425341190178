import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import backgroundImage from '../../assets/images/careers_banner1.jpg'
import CareerEmploymentContent from "./career-employment-content";

function CareerEmployment() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Career Employment" bgImage={backgroundImage}/>
            <CareerEmploymentContent/>
        </>
    )
}

export default CareerEmployment
import React from 'react'
import HomeSlider from './slider'
import HomeAbout from './about'
import HomeCourses from './courses'
import HomePride from './why-us'
import HomeEvents from './event'
import HomeVideo from './Video'
import HomeNews from './news'
import HomeAdmission from './admission'
import WelcomeMessage from "./welcome-message";

export default function LandingPage() {
  return (
    <div>
      <HomeSlider />

      <HomeAbout />
      <HomeCourses />
      <WelcomeMessage />
      <HomePride />
      <HomeEvents />
      <HomeVideo />
      <HomeNews />
      <HomeAdmission />
    </div>
  )
}

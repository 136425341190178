import React from 'react'
import Bredcom from '../../Bredcom/Main'

function Main() {
  return (
    <>
        <Bredcom title="Home" subtitle="404 Error"/>
    </>
  )
}

export default Main
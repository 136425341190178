import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import ResearchGroupContent from "./research-group-content";
function ResearchGroup() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Research Group and Centre"/>
            <ResearchGroupContent/>
        </>
    )
}

export default ResearchGroup
import React  from 'react'

function PartnershipContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Partnership at Cosmopolitan University
                                    </h5>
                                    <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we value collaborative partnerships as essential elements of our commitment to academic excellence, innovation, and societal impact. We actively seek partnerships with leading institutions, organizations, and industry leaders to foster knowledge exchange, enhance research capabilities, expand educational opportunities, and create a positive impact on the communities we serve. Our partnerships are built on mutual respect, shared goals, and a commitment to excellence. Here's an overview of our partnership initiatives:
                                    </p>
                                    <p>
                                        <strong>1. Academic Partnerships: </strong>
                                        We forge strategic collaborations with reputable academic institutions both locally and internationally. These partnerships facilitate faculty exchanges, joint research projects, collaborative curriculum development, and student exchange programs. Through academic partnerships, we broaden our educational offerings, enhance the quality of teaching and research, and provide our students with a truly global learning experience.
                                    </p>
                                    <p>
                                        <strong>2. Industry Partnerships: </strong>
                                        We actively engage with industry partners to bridge the gap between academia and the real world. Collaborations with industry leaders enable us to align our curriculum with industry needs, foster research and innovation, and provide students with practical training, internships, and employment opportunities. Our industry partners also contribute valuable insights, expertise, and resources to enhance our academic programs and ensure their relevance in the ever-evolving job market.
                                    </p>
                                    <p>
                                        <strong>3. Research Collaborations: </strong>
                                        We believe in the power of collaborative research to address complex societal challenges. We establish research collaborations with government agencies, research institutions, non-profit organizations, and industry partners to leverage expertise, resources, and funding opportunities. These collaborations enable us to undertake impactful research projects, drive innovation, and contribute to scientific advancements and technological breakthroughs.
                                    </p>
                                    <p>
                                        <strong>4. Community Engagement Partnerships: </strong>
                                        We actively collaborate with community organizations, non-governmental organizations (NGOs), and local stakeholders to address community needs and drive social change. These partnerships enable us to develop community-oriented programs, promote sustainable development, and empower individuals and communities through education, capacity building, and outreach initiatives.
                                    </p>

                                    <p>
                                        <strong>5. International Partnerships: </strong>
                                         We foster international partnerships to promote cultural diversity, global perspectives, and international collaboration. These partnerships facilitate student and faculty exchanges, joint research projects, and collaborative initiatives that enrich the academic experience and promote global citizenship. We actively seek partnerships with universities and organizations around the world to broaden our international networks and enhance our global standing.
                                    </p>
                                    <p>
                                        <strong>6. Entrepreneurship and Innovation Partnerships: </strong>
                                        We collaborate with entrepreneurial ecosystems, incubators, and startups to foster innovation, entrepreneurship, and technology transfer. These partnerships provide our students and researchers with opportunities to develop entrepreneurial skills, access mentorship and funding, and transform their ideas into successful ventures. By nurturing an innovation ecosystem, we contribute to economic growth and job creation.
                                    </p>
                                    <p>
                                        <strong>7. Government Collaborations: </strong>
                                        We work closely with government agencies, ministries, and policy-makers to contribute to national development priorities, support evidence-based policymaking, and address societal challenges. Our collaborations with the government facilitate access to research funding, policy forums, and opportunities to influence decision-making processes.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    We welcome inquiries and proposals from potential partners who share our vision and values. Together, we can create meaningful collaborations that drive innovation, advance knowledge, and make a positive impact on society. If you are interested in exploring partnership opportunities with Cosmopolitan University, please contact our Partnership Office.
                                </p>
                                <p>
                                    JoinJoin us at Cosmopolitan University and be part of a vibrant network of partners committed to academic excellence, research innovation, and societal transformation. Together, we can shape the future of education, research, and community development.
                                </p>
                            </div>
                            <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                        </div>
                        {/*comment*/}
                    </div>
                </div>
            </section>

        </>
    )
}

export default PartnershipContent
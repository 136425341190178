import React from 'react'
import { Link } from 'react-router-dom'
import { Logo, projectAddress, projectEmail, projectFacebook, projectInstagram, projectPhone, projectTwitter } from '../../resources/constants'

function Main() {
    return (
        <>
            <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#125875", backgroundImage: `url(${require("../../assets/images/banner2_dark.png")})` }} >
                <div className="footer-top pb-70">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>About Us</h2>
                                    </div>
                                    <div className="f-contact">
                                        <p>
                                            Cosmopolitan University is a prestigious institution of higher learning dedicated to providing exceptional education and innovative learning experiences to students from all walks of life.
                                        </p>
                                    </div>
                                    <div className="footer-social mt-10">
                                        <a href={projectFacebook}>
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a href={projectInstagram}>
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a href={projectTwitter}>
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Useful Links</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us"> About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/admission/course-finder">Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/news"> News</Link>
                                            </li>
                                            <li>
                                                <Link to="/events">Events </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Services</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="https://admission.cosmopolitan.edu.ng/" target='_blank'>Admission</Link>
                                            </li>
                                            <li>
                                                <Link to="https://staff.cosmopolitan.edu.ng" target='_blank'> Staff</Link>
                                            </li>
                                            <li>
                                                <Link to="https://student.cosmopolitan.edu.ng" target='_blank'>Students</Link>
                                            </li>
                                            <li>
                                                <Link to="https://jobs.cosmopolitan.edu.ng" target='_blank'>Careers </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/research/research-area">Research </Link>
                                            </li> */}
                                            <li>
                                                <Link to="/services/library">Library </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="f-contact">
                                        <ul>
                                            <li>
                                                <i className="icon fal fa-phone" />
                                                <span style={{ marginTop: "20px", verticalAlign: "center", alignItems: "center", justifyContent: "center" }} className='mt-10'>
                                                    <Link to={`tel:${projectPhone}`}>{projectPhone}</Link>
                                                    {/* <br />
                                                    <Link to={`tel:${projectPhone}`}>{projectPhone} </Link> */}
                                                </span>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-envelope" />
                                                <span className='mt-10' >
                                                    {/* <Link to={`mailto:${projectEmail}`}>{projectEmail}</Link>
                                                    <br /> */}
                                                    <Link to={`mailto:${projectEmail}`}>{projectEmail}</Link>
                                                </span>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-map-marker-check" />
                                                <span className='mt-10 f-contact'>
                                                    {projectAddress}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="copy-text">
                                    <span>
                                        <Link to="/">
                                            <img src={Logo} alt="img" style={{ width: "40px" }} />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center"></div>
                            <div className="col-lg-4 text-right text-xl-right">
                                Copyright &copy; Cosmopolitan University {new Date().getFullYear()} . All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { ImageUrlFormatter, serverLink } from '../../resources/constants';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-left"></i></button>

    );
}

function HomeSlider() {
    const Background = require("../../assets/images/COSMOPOLITAN_UNIVERSITY_018_dark.jpg")
    const Backgroundtwo = require("../../assets/images/COSMOPOLITAN_UNIVERSITY_004_dark.jpg")

    const settings = {
        autoplay: true,
        autoplaySpeed: 10000,
        dots: false,
        fade: true,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            { breakpoint: 1200, settings: { dots: false, arrows: false } }
        ]
    };
    const [sliderList, setSliderList] = useState([])

    const getSliders = async () => {
        const { data } = await axios.get(`${serverLink}slider/homepage`);
        setSliderList(data)
    }

    useEffect(() => {
        getSliders();
    }, [])
    return (
        <>
            <section id="home" className="slider-area fix p-relative">
                <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                    {
                        sliderList.length > 0 ?
                            sliderList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="single-slider slider-bg" style={{ backgroundImage: `url(${ImageUrlFormatter(item.ImagePath, 'sliders_uploads')})`, backgroundSize: "cover" }} >
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-7">
                                                        <div className="slider-content s-slider-content mt-130">
                                                            {/* <h5 data-animation="fadeInUp" data-delay=".4s">
                                                                welcome To Qeducato
                                                            </h5> */}
                                                            <h2 data-animation="fadeInUp" data-delay=".4s">
                                                                {item.Title}
                                                            </h2>
                                                            <p data-animation="fadeInUp" data-delay=".6s">
                                                                {item.Caption !== null && item.Caption.substr(0, 200)}...
                                                            </p>
                                                            <div className="slider-btn mt-30">
                                                                <Link to={item.BtnLink} className="btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                                                    {item.BtnName} <i className="fal fa-long-arrow-right" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 p-relative"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div>
                                <div className="single-slider slider-bg" style={{ backgroundImage: `url(${Backgroundtwo})`, backgroundSize: "cover" }} >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-7">
                                                <div className="slider-content s-slider-content mt-130">
                                                    <h5 data-animation="fadeInUp" data-delay=".4s">
                                                        welcome To Cosmopolitan University
                                                    </h5>
                                                    <h2 data-animation="fadeInUp" data-delay=".4s">
                                                        Education is the best key success in life
                                                    </h2>
                                                    <p data-animation="fadeInUp" data-delay=".6s">
                                                        Welcome to Cosmopolitan University, where knowledge meets innovation, and dreams become reality.
                                                    </p>
                                                    <div className="slider-btn mt-30">
                                                        <Link to="/about-us" className="btn ss-btn mr-15" data-animation="fadeInLeft" data-delay=".4s" >
                                                            Discover More <i className="fal fa-long-arrow-right" />
                                                        </Link>
                                                        <Link to="/contact-us" className="btn ss-btn active" data-animation="fadeInLeft" data-delay=".4s" >
                                                            Contact Us <i className="fal fa-long-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-5 p-relative"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                </Slider>
            </section>

            <section className="service-details-two p-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="assets/img/icon/sve-icon4.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h4>
                                            <Link to="#">Academic Excellence</Link>
                                        </h4>
                                        <p>
                                            At Cosmopolitan University, we are committed to delivering academic programs of the highest quality.
                                        </p>
                                        <Link to="/about">
                                            Read More <i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07 active">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="assets/img/icon/sve-icon5.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h4>
                                            <Link to="/#">Innovative Learning</Link>
                                        </h4>
                                        <p>
                                            We embrace a technology-driven learning, ensuring that our students have access to cutting-edge tools and resources that enhance their educational experience.
                                        </p>
                                        {/* <Link to="/about">
                                            Read More <i className="fal fa-long-arrow-right" />
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="assets/img/icon/sve-icon6.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h4>
                                            <Link to="/#">Research and Innovation</Link>
                                        </h4>
                                        <p>
                                            We are dedicated to expanding knowledge through research and active engagement with industry and society.
                                        </p>
                                        <Link to="/research/research-area">
                                            Read More <i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSlider
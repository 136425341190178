import React  from 'react'
import { Link } from 'react-router-dom';
import Img from '../../../assets/images/VC.jpg'
import Bredcom from "../../../pages/Bredcom/bredcom";
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../../resources/constants";

function VCWelcomeMessage() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Vice Chancellor Message"/>

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__content">
                                    <div className="bsingle__post-thumb mb-20">
                                        <div className="row">
                                            <div className="col-md-10 offset-sm-1">
                                                <img className="img-thumbnail" src={Img} alt="" />
                                                <div className="text-center mt-0">
                                                    <h2 style={{marginBottom: '-5px'}}>Prof. Carl Adams</h2>
                                                    <small style={{marginTop: '-50px'}}> (Vice-Chancellor) </small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{textAlign: "justify"}}>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Cosmopolitan University of Abuja is a dynamic university that provides high quality research informed education to our students. We continue to invest in our staff, students, and facilities as part of our goal to become a leading university in Nigeria recognised internationally. Our mission is to educate to the highest standards and conduct impactful research contributing towards a better future for all. Inclusivity and diversity are at the heart of our community.
                                        </p>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Our teaching aims to provide high-quality courses providing robust learning experiences preparing students for their careers. We draw upon an international faculty and resources bringing global best practices and insights. Our courses use the best of learning materials from around the world ensuring exacting standards making our graduates very marketable in their professional careers and thought leaders of the future.
                                        </p>
                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Our ethos is one of support, trust integrity and respect while valuing diversity. We aim to attract, develop, and retain highly skilled and motivated staff by living our values and nurturing the creation of new ideas and innovations.
                                        </p>

                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            We aim to create a supportive culture, governance, and infrastructure, built around doing the very best we each can, ensuring a sustainable, safe, and stable future for staff and students. We adopt strong governance processes to ensure our governance supports the scale and complexity of our dynamic and growing organisation.  We achieve financial sustainability by ensuring sufficient funds to continue to invest prudently and confidently to meet our aspirations.
                                         </p>

                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            Our research follows the principles of rigor, relevance, impact, and diversity. We support research with training, providing robust research with a strong ethical base. We encourage impactful curiosity-led research, disciplinary excellence, and interdisciplinary collaboration internally, nationally, and internationally.  Our research aims to address some of the most important and challenging issues facing society in Nigeria, Africa and globally. Our impactful research will also feed into and inform our high-quality courses to ensure they are continually current and relevant for the business and professional marketplaces after students graduate.
                                         </p>

                                        <p className="txt-clr" style={{textAlign: 'justify'}}>
                                            We embed entrepreneurship and innovation to encourage partnerships between business, professions, innovators, other academic organisations, and researchers.
                                            Our students will be well placed to be the academic, business, industry and thought leaders of the future.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/about/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default VCWelcomeMessage
import React  from 'react'
import { Link } from 'react-router-dom';
import Fees from '../../assets/images/fees.jpg'
import Bredcom from "../Bredcom/bredcom";
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";

function Tuition() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Tuition Fees"/>

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__post-thumb">
                                    <img src={Fees} alt="" />
                                </div>
                                <div className="">
                                    <h3>
                                        Tuition Fees at Cosmopolitan University
                                    </h3>
                                    <div style={{textAlign: "justify"}}>
                                        <p>At Cosmopolitan University, we strive to provide quality education of international standard at an affordable cost. We understand that financing your education is an important consideration, and we are committed to transparency when it comes to our tuition fees. Below, you will find information about our fee structure for the upcoming academic year.
                                        </p>
                                        <p> <strong>Tuition Fees:</strong>
                                            The tuition fees at Cosmopolitan University vary depending on the program and faculty. Please refer to the specific program pages on our website or contact our admissions office for detailed information about the tuition fees associated with your chosen program
                                        </p>
                                        <p>
                                            <strong>Additional Charges:</strong>
                                              In addition to tuition fees, there may be additional charges related to student services, facilities, and resources. These charges are designed to enhance your learning experience and support the various services provided by the university. The specific details of any additional charges will be communicated to you during the admissions process or can be obtained by contacting our administrative office.
                                        </p>
                                        <p>
                                            <strong>Payment Options:</strong>
                                            We understand that different students have different financial circumstances. To provide flexibility, Cosmopolitan University offers various payment options to suit your needs. These options may include installment plans, online payment systems, and other convenient methods. Our financial services team will guide you through the payment process and assist you in finding the most suitable payment option for you.
                                        </p>
                                        <p>
                                            <strong>Fee Refund Policy:</strong>
                                            Cosmopolitan University has a well-defined fee refund policy in place. This policy outlines the terms and conditions under which refunds may be granted and the applicable timelines. We encourage you to familiarize yourself with our refund policy to understand your rights and obligations.
                                        </p>
                                        <p>
                                            Please note that tuition fees are subject to periodic review and may be adjusted in accordance with economic factors and the university's financial considerations. Any changes to the fee structure will be communicated to enrolled students in a timely manner.
                                        </p>
                                    </div>
                                </div>
                                {/*<div className="bsingle__content">*/}
                                {/*    <p>*/}
                                {/*       Tuition Fees at Cosmopolitan University*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Tuition